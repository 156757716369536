import Equation from "components/equation";
import EquationRef from "components/equationRef";
import Figures from "components/figures";
import Label from "components/label";
import * as React from 'react';
export default {
  Equation,
  EquationRef,
  Figures,
  Label,
  React
};